<template>
  <div>
    <div id="splash" class="about">
      <h2>Hi! ✨🦊</h2>
      <div class="arrowDown">
        <a @click="scrollTo('#AboutHeader')"
          ><font-awesome-icon :icon="['fas', 'chevron-down']"
        /></a>
      </div>
    </div>
    <article class="about">
      <h2 id="AboutHeader">About</h2>
      <p>
        Æther Fox is a 28-year-old independent musician from Switzerland. They
        are currently hard at work on their first album, &rdquo;DÆMONS&rdquo;.
      </p>
      <p>
        As a producer, Æther Fox does not adhere to a specific genre. Their main influences are ethereal wave, dream pop and folk music.
      </p>
    </article>
    <div class="copyright">
      <p>&copy; 2023 Æther Fox/Æther Fox Records.</p>
    </div>
  </div>
</template>
<style lang="scss">
h2 {
  font-size: 4rem;
  @media screen and (min-width: 650px) {
    font-size: 8rem;
  }
}
</style>
<script>
export default {
  name: 'About',
  data: function () {
    return {};
  },
  methods: {
    scrollTo: function (targetobject, event) {
      this.scrollToHandler(targetobject, event);
    }
  }
};
</script>
